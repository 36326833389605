import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { TCLASIFICACION_SERVICIO_CONTRATADO } from '@/utils/consts'
import { currentDate } from '@/utils/date'

export default {
  async selectSistemaCuota (Vue, filter, search, sorter, page, idsistema) {
    const apiFilter = new APIFilter()
    const apiFilterFechas = new APIFilter()
    apiFilterFechas
      .setOperator(API_FILTER_OPERATORS.OR)
      .addGT('ffin_cuota', currentDate())
      .addIsNull('ffin_cuota', false)
    apiFilter
      .addExact('idsistema', idsistema)
      .addExact('idtclasificacion_servicio', TCLASIFICACION_SERVICIO_CONTRATADO.clasificacion.mantenimiento)
      .addGT('estado', 0)
      .addNestedFilter(
        apiFilterFechas
      )
    if (search) {
      apiFilter.addILike('concepto_cuota_descripcion', search)
    }
    const resp = await Vue.$api.call(
      'sistemaCuota.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSistemaCuotaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsistema_cuota', pks)
    const resp = await Vue.$api.call('sistemaCuota.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
